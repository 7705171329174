<template>
  <div class="searcher custom-shadow">
    <itc-card color="#343a40">
      <div slot="header" class="text-center text-white">
        <h4>Buscador</h4>
      </div>
      <div slot="body" class="row">
        <div class="col-4">
          <div class="searchByOrderNumber">
            <label for="numberSearcher">Por número de orden</label>
            <input
              ref="orderNumberInput" 
              v-model="numberSearcher" 
              @keyup.enter="searchFunction(numberSearcher, 'orderNumber');numberSearcher = ''"
              class="form-control" type="text">
            </div>
          </div>
          <div class="col-4">
            <div class="SearchByDate">
              <label for="nameSearcher">Por fecha</label>
              <input
                v-model="dateSearcher" 
                @change="searchFunction(dateSearcher, 'date')"
                class="form-control" type="date">
              </div>
            </div>
            <div class="col-4">
              <div class="form-group SearchByCardName">
                <label for="cardSearcher">Por tarjeta usada</label>
                <select 
                  v-model="cardSearcher"
                  @change="searchFunction(cardSearcher, 'card')"
                  class="form-control" 
                  id="cardSearcher">
                  <option></option>
                  <option v-for="(card, i) in cards">{{card.name}}</option>
                </select>
              </div>
            </div>
          </div>
        </itc-card>
      </div>
    </template>

<script>
import Card from '@/components/mix/Card'
import axios from 'axios'

export default {
  components: {
    'itc-card': Card
  },
  data(){
    return {
      numberSearcher: '',
      dateSearcher: null,
      cardSearcher: '',
      cards: []
    }
  },
  async created(){
    const response = await axios.get('/card')
    this.cards = response.data
    this.$nextTick(() => {
      this.$refs.orderNumberInput.focus()
    })
  },
  props: {
    searchFunction: Function
  }
}
</script>

<style lang="scss" scoped>
.custom-border{
  border-bottom: 1px solid lightgray;
}

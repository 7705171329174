<template>
    <div class="orders-list custom-shadow">
        <table id="tablePreview" class="table table-striped">
            <thead class="thead-dark">
                <tr>
                    <th>#Orden</th>
                    <th>Fecha</th>
                    <th>Apellido</th>
                    <th>Nombre</th>
                    <th>Items</th>
                    <th>Monto</th>
                    <th>Estado</th>
                </tr>
            </thead>
            <tbody>
                <tr  v-if="orders.length == 0">
                    <td colspan="8" class="text-center">
                        <h1>Sin ordenes</h1>
                    </td>
                </tr>
                <tr
                    v-for="(order, i) in orders" 
                    :key="i" 
                    class="order-item"
                    @click="selectOrder(i)">
                    <th scope="row">{{order.orderNumber}}</th>
                    <td>{{order.createdAt}}</td>
                    <td v-if="order.user">{{order.user.surname}}</td>
                    <td v-else>-</td>   
                    <td v-if="order.user">{{order.user.name}}</td>
                    <td v-else>-</td>   
                    <td>{{order.cart.totalQty}}</td>
                    <td>${{order.cart.totalAmount}}</td>
                    <td>{{order.status | filterOrderStatus}}</td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
import * as pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
pdfMake.vfs = pdfFonts.pdfMake.vfs;

import * as utils from '@/assets/utils/utils.js'

export default {
    props: {
        orders: Array,
        selectOrder: Function
    },
    filters: {
        filterOrderStatus(name){
            return utils.filterOrderStatus(name)
        }
    }
}
</script>

<style lang="scss" scoped>
.order-item:hover{
    background-color: lightgreen;
}
</style>
<template>
    <div class="localshop-orders">
        <itc-localshop-nav>
            <router-link tag="button" class="btn btn-warning mt-3 px-3" to="/localshop/menu">Volver</router-link>
        </itc-localshop-nav>
        <div class="main">
            <itc-orders class="m-2"></itc-orders>
        </div>
    </div>
</template>

<script>
import Orders from '@/views/admin/Orders'
import LocalShopNav from '@/components/shared/localshop/LocalShopNav'

export default {
    components: {
        'itc-orders': Orders,
        'itc-localshop-nav': LocalShopNav
    }
}
</script>

<style lang="scss" scoped>
.main{
    padding-top: 70px;
}
</style>
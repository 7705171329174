<template>
    <itc-card color="#343a40" class="custom-shadow">
        <div slot="header" class="text-white">
            <div class="row">
                <div class="col-3">
                    <button 
                        @click="$emit('click-on-back-button')"
                        class="btn btn-info"> Volver
                    </button>
                </div>
                <div class="text-center col">
                    <h3>Orden #{{order.orderNumber}}</h3>
                </div>
                <div class="col-3">
                    <button @click="printOrder" class="btn btn-success float-right mx-2" >Imprimir</button>
                </div>
            </div>
        </div>
        <div slot="body">
            <div class="row">
                <div class="col-8">
                    <div class="p-3">
                        <h4>Datos de la orden</h4>
                        <hr>
                        <ul>
                            <li v-if="order.user"><strong>Cliente: </strong>{{order.user.name}} {{order.user.surname}}</li>
                            <li v-else><strong>Cliente: </strong>Sin usuario</li>
                            <li><strong>Fecha de emisión: </strong>{{order.createdAt}}</li>
                            <li><strong>Estado: </strong>{{order.status | filterStatus}}</li>
                            <li><strong>Es compra online: </strong>{{order.onlineBuy ? 'Si' : 'No'}}</li>
                        </ul>
                        <h4>Métodos de pago</h4>
                        <hr>
                        <ul>
                            <li v-for="(payment, i) in order.payment" :key="i">
                                <strong>{{payment.methodName | filterMethodName}}: </strong>
                                ${{payment.amount}}
                                <small v-if="payment.methodName == 'card'">
                                  | {{payment.cardInfo.cardName}} en {{payment.cardInfo.selectedFee}} cuotas
                                </small>
                            </li>
                        </ul>
                        <div v-if="order.observ">
                            <h4>Observaciones</h4>
                            <hr>
                            <p>{{order.observ}}</p>
                        </div>
                    </div>
                </div>
                <div class="col-4">
                    <itc-card  color="#fff" class="custom-shadow px-2">
                        <div slot="header">
                            <h4>Productos</h4>
                        </div>
                        <div slot="body">
                            <table class="w-100">
                                <thead>
                                    <tr>
                                        <th>Nombre</th>
                                        <th>Cantidad</th>
                                        <th>Sub-total</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(product, i) in order.cart.products" :key="i">
                                        <td><small>{{product.name}}</small></td>
                                        <td><small>{{product.qty}}</small></td>
                                        <td><small>{{product.subTotal}}</small></td>
                                    </tr>
                                    <tr class="text-white" style="background-color:#343a40">
                                        <td></td>
                                        <td>Total: </td>
                                        <td>${{order.cart.totalAmount}}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </itc-card>
                </div>
            </div>
        </div>
    </itc-card>
</template>

<script>
import axios from 'axios'
import Card from '@/components/mix/Card'

import * as utils from '@/assets/utils/utils.js'

export default {
    props: {
        order: Object,
    },
    components: {
        'itc-card': Card
    },
    methods: {
        async printOrder(){
            const resp = await axios.get(`/order/print-definition?searcher=${this.order._id}`)
            const newWindow = window.open({});
            newWindow.document.write(resp.data)
            setTimeout(() => {
                newWindow.print()
                newWindow.close()
            }, 2000)
        }
    },
    filters: {
        filterMethodName(name){
            return utils.filterPaymentMethodName(name)
        },
        filterStatus(status){
            return utils.filterOrderStatus(status)
        }
    }
}
</script>

<style lang="scss" scoped>
li{
    list-style: none;
}
th {
  text-align: left;
}

th, td {
    border-bottom: 1px solid transparentize($color: #D3D3D3, $amount: 0.2);
    padding: 6px;
}
</style>

<template>
    <div class="orders">
        <h4 class="pt-3">Ordenes</h4>
        <hr>
        <itc-orders-searcher :searchFunction="searchOrders" class="my-4"></itc-orders-searcher>
        <transition name="fade" mode="out-in">
            <keep-alive>
                <component
                    :is="actualComponent"
                    :orders="orders"
                    :order="order"
                    :selectOrder="selectOrder"
                    @click-on-back-button="actualComponent = 'itc-orders-table'">
                </component>
            </keep-alive>
        </transition>
    </div>
</template>

<script>
import axios from 'axios'

import OrdersSearcher from '@/components/admin/orders/OrderSearcher'
import OrdersTable from '@/components/admin/orders/OrdersTable'
import OrderControlPanel from '@/components/admin/orders/OrderControlPanel'

export default {
 data(){
     return {
         orders: [],
         order: {},
         actualComponent: 'itc-orders-table',
     }
 },
 methods: {
     async searchOrders(searcher, type){
        const resp = await axios.get(`/order/${type}?searcher=${searcher}`)
        this.actualComponent = 'itc-orders-table'
        this.orders = resp.data
     },
     selectOrder(index){
        this.actualComponent = 'itc-order-panel'
        this.order = this.orders[index] 
    }
 },
 components: {
    'itc-orders-table': OrdersTable,
    'itc-orders-searcher': OrdersSearcher,
    'itc-order-panel': OrderControlPanel
 },
 async created(){
     const resp = await axios.get('/order/lastUpdate');
     this.orders = resp.data
 }   
}
</script>

<style lang="scss" scoped>
.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>